import { RoomCode, FloorLevel, FloorType } from 'models';
import { ENG } from 'config/constants';

class CentrisRoom {
  id: number;

  code: RoomCode;

  dimensions: string;

  floor_level: FloorLevel;

  flooring_type_code: FloorType;

  additional_information: string;

  additional_information_fr: string;

  constructor(data: object = {}) {
    Object.assign(this, data);
    if (this.code) {
      this.code = new RoomCode(this.code);
    }
    if (this.floor_level) {
      this.floor_level = new FloorLevel(this.floor_level);
    }
    if (this.flooring_type_code) {
      this.flooring_type_code = new FloorType(this.flooring_type_code);
    }
  }

  getRoomName(language: string): string {
    return this.code ? this.code.getDescription(language) : '';
  }

  getFloor(language: string): string {
    return this.floor_level ? this.floor_level.getDescription(language) : '';
  }

  getRoomDimensions(language: string): string {
    if (language === ENG) {
      // remove 'p' from dimensions
      return this.dimensions.replace('P', 'ft');
    }
    return this.dimensions.replace('P', 'pi');
  }

  getFloorType(language: string): string {
    return this.flooring_type_code ? this.flooring_type_code.getDescription(language) : '';
  }
}

export default CentrisRoom;
