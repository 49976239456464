import { ENG } from 'config/constants';

class FloorLevel {
  description_en: string;

  description_fr: string;

  domain: string;

  short_description_en: string;

  short_description_fr: string;

  value: string;

  constructor(data: object = {}) {
    Object.assign(this, data);
  }

  getDescription(language: string): string {
    return language === ENG ? this.description_en : this.description_fr;
  }
}
export default FloorLevel;
