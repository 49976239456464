import classNames from 'classnames';

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import { GOOGLE_MAPS_API_KEY } from 'config/constants';

const useStyles = makeStyles({
  mapBox: {
    position: 'relative',
    width: '100%',
    paddingTop: '40%',
  },
  mapBoxContent: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
});

interface MarkerProps {
  lat: number;
  lng: number;
}

const Marker = ({ lat, lng }: MarkerProps) => {
  // console.log(lat, lng);

  return (
    <div
      style={{
        position: 'absolute',
        width: 30,
        height: 30,
        left: -15,
        top: -15,
        border: '5px solid #f44336',
        borderRadius: 15,
        backgroundColor: 'white',
        textAlign: 'center',
        color: '#3f51b5',
        fontSize: 16,
        fontWeight: 'bold',
        padding: 4,
        boxSizing: 'border-box',
      }}
    />
  );
};

interface Props {
  className: string;
  lat: number;
  lng: number;
  zoom?: number;
}

function HvGoogleMap({ className, lat, lng, zoom }: Props) {
  const styles = useStyles();

  return (
    <Box className={classNames(className, styles.mapBox)}>
      <Box className={styles.mapBoxContent}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
          defaultCenter={{ lat, lng }}
          defaultZoom={zoom || 11}
        >
          <Marker lat={lat} lng={lng} />
        </GoogleMapReact>
      </Box>
    </Box>
  );
}

HvGoogleMap.defaultProps = {
  className: null,
};

HvGoogleMap.propTypes = {
  className: PropTypes.string,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
};

export default HvGoogleMap;
