import { CentrisRoom } from 'models';
import { ENG } from 'config/constants';
import moment from 'moment';
import { groupBy } from 'lodash';
import CentrisListingPicture from './CentrisListingPicture';

class CentrisListing {
  id: number;

  appartement: string;

  addendas: any;

  bathroom_count: number;

  bedroom_count: number;

  building_type: any;

  building_surface_area: number;

  building_surface_area_unit: string;

  category: any;

  construction_year: number;

  civic_number_start: string;

  civic_number_end: string;

  close_to: string;

  characteristics: any;

  code_status: string;

  description: any;

  date_sold: string;

  expenses: any;

  exclusion_en: string;

  exclusion_fr: string;

  fourth_broker: any;

  street: string;

  inclusion_fr: string;

  inclusion_en: string;

  latitude: number;

  longitude: number;

  links: any;

  living_area: number;

  living_area_unit: string;

  land_area_unit: string;

  land_area: number;

  location_price: number;

  mls: string;

  main_broker: any;

  municipality: any;

  no_legal_guarantee: any;

  open_houses: any;

  occupation_delay_en: string;

  occupation_delay_fr: string;

  price: number;

  pictures: CentrisListingPicture[];

  property_type: any;

  parking: string;

  postalcode: string;

  price_including_taxes: number;

  price_plus_taxes: number;

  rooms: CentrisRoom[];

  room_count: number;

  renovation: any;

  second_broker: any;

  third_broker: any;

  rent_price_frequency: string;

  unit_detail: any;

  unit_summary: any;

  waterroom_count: number;

  constructor(data: object = {}) {
    Object.assign(this, data);
  }

  getDisplayPrice(language: string): string {
    let display_price = '';
    const options: Intl.NumberFormatOptions = {
      style: 'currency',
      currency: 'CAD',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    };
    const taxSuffix = language === ENG ? 'GST/QST' : 'TPS/TVQ';
    const or = language === ENG ? 'or' : 'ou';
    const sold = language === ENG ? 'Sold' : 'Vendu';
    if (this.price && this.price > 1) {
      display_price = `${this.price.toLocaleString('en-CA', options)}`;
    }
    if (this.price === 1 && this.location_price && this.rent_price_frequency) {
      display_price = `${this.location_price.toLocaleString('en-CA', options)} / ${
        this.rent_price_frequency
      }`;
    }
    if (this.price > 1 && this.location_price > 1 && this.rent_price_frequency) {
      display_price = `${this.price.toLocaleString(
        'en-CA',
        options,
      )} ${or} ${this.location_price.toLocaleString('en-CA', options)} / ${
        this.rent_price_frequency
      }`;
    }
    if (this.price_plus_taxes) {
      display_price = `${this.price_plus_taxes.toLocaleString('en-CA', options)} + ${taxSuffix}`;
    }
    if (this.price_including_taxes) {
      display_price = `${this.price_including_taxes.toLocaleString('en-CA', options)}`;
    }
    if (this.code_status === 'VE' && this.date_sold) {
      display_price = sold;
    }
    return display_price;
  }

  getFormattedPrice(): string {
    let formattedPrice = '';
    if (this.price) {
      const options: Intl.NumberFormatOptions = {
        currency: 'CAD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      };
      formattedPrice = this.price.toLocaleString('en-CA', options);
    }
    return formattedPrice;
  }

  getMortgage(downpayment): number {
    let mortgage = 0;
    if (this.price) {
      mortgage = this.price - downpayment;
    }
    return mortgage;
  }

  getListingType(language: string): string {
    let listingType = '';
    if (this.property_type) {
      listingType =
        language === ENG ? this.property_type.description_en : this.property_type.description_fr;
    }
    if (this.municipality.description) {
      if (this.property_type) {
        listingType += ' - ';
      }
      listingType += `${this.municipality.description}`;
    }
    return listingType;
  }

  getAddress(language: string): string {
    let listingAddress = '';
    if (this.civic_number_start) {
      listingAddress = `${this.civic_number_start}`;
    }
    if (this.civic_number_end) {
      listingAddress += ` - ${this.civic_number_end}`;
    }
    listingAddress += ` ${this.street}`;
    if (this.appartement) {
      listingAddress += `, app. ${this.appartement}`;
    }
    if (this?.municipality?.description) {
      listingAddress += `, ${this.municipality.description}`;
    }
    if (this?.municipality?.region) {
      listingAddress += `, ${
        language === ENG
          ? this.municipality.region.description_en
          : this.municipality.region.description_fr
      }`;
    }
    if (this?.postalcode) {
      listingAddress += `, ${this.postalcode}`;
    }
    return listingAddress;
  }

  getListingDescription(language: string): string {
    const description_fr = this.description?.find((d) => {
      return d.language === 'F';
    });
    const description_en = this.description?.find((d) => {
      return d.language === 'A';
    });
    if (language === ENG) {
      return description_en?.text || description_fr?.text || '';
    }
    return description_fr?.text || description_en?.text || '';
  }

  getListingAddendum(language: string): any {
    const texts_fr = this.addendas?.filter((a) => {
      return a.language === 'F';
    });
    const texts_en = this.addendas?.filter((a) => {
      return a.language === 'A';
    });
    let addendum_en = '';
    let addendum_fr = '';
    if (texts_en.length) {
      addendum_en = '<Typography>';
    }
    if (texts_fr.length) {
      addendum_fr = '<Typography>';
    }
    if (texts_fr.length) {
      texts_fr.map((text) => {
        if (text.text === null && !addendum_fr.endsWith(':')) {
          addendum_fr += '<br /><br />';
        } else if (text.text === null && addendum_fr.endsWith(':')) {
          addendum_fr += '<br />';
        } else if (text?.text?.startsWith('-') && !addendum_fr.endsWith('<br /><br />')) {
          addendum_fr += `<br />${text.text}`;
        } else {
          addendum_fr += text.text;
        }
        return '';
      });
      addendum_fr += '</Typography>';
    }
    if (texts_en.length) {
      texts_en.map((text: any) => {
        if (text.text === null && !addendum_en.endsWith(':')) {
          addendum_en += '<br /><br />';
        } else if (text.text === null && addendum_en.endsWith(':')) {
          addendum_en += '<br />';
        } else if (text?.text?.startsWith('-') && !addendum_en.endsWith('<br /><br />')) {
          addendum_en += `<br />${text.text}`;
        } else {
          addendum_en += text.text;
        }
        return '';
      });
      addendum_en += '</Typography>';
    }
    let addendum;
    if (language === ENG) {
      addendum = addendum_en || addendum_fr || '';
    }
    addendum = addendum_fr || addendum_en || '';
    return { __html: addendum };
  }

  getListingCharacteristics(): any {
    let characteristics = [];
    if (this.characteristics) {
      const groupedCharacteristics = groupBy(this.characteristics, 'caracteristic_type_code');
      characteristics = Object.keys(groupedCharacteristics).map((key) => {
        return {
          type: groupedCharacteristics[key][0].type,
          subtypes: groupedCharacteristics[key],
        };
      });
    }
    return characteristics;
  }

  getListingCharacteristicsValues(subtypes, code, language): string {
    let values = '';
    if (this.characteristics) {
      subtypes.forEach((s) => {
        const foundSubtype = s.subtype.find((subtype) => {
          return subtype.caracteristic_code === code;
        });
        if (foundSubtype) {
          values += `${
            language === ENG ? foundSubtype.description_en : foundSubtype.description_fr
          }`;
        }
        if (subtypes.indexOf(s) !== subtypes.length - 1) {
          values += ', ';
        }
      });
    }
    return values;
  }

  getListingTotalUnitCount(): number {
    let totalUnitCount = 0;
    if (this.unit_summary) {
      this.unit_summary.forEach((unit) => {
        totalUnitCount += unit.count;
      });
    }
    return totalUnitCount;
  }

  getListingLinks(): any {
    let sortedLinks = [];
    if (this.links) {
      sortedLinks = this.links.sort((a, b) => {
        return a.index - b.index;
      });
    }
    return sortedLinks;
  }

  getOpenHouses(): any {
    let upcomingOpenHouses = [];
    if (this.open_houses) {
      this.open_houses.forEach((openHouse) => {
        if (!moment(openHouse.end_date).isBefore(new Date())) {
          upcomingOpenHouses.push(openHouse);
        }
      });
      upcomingOpenHouses = upcomingOpenHouses.sort((a, b) => {
        return a.start_date - b.start_date;
      });
    }
    return upcomingOpenHouses;
  }

  getExpenseTotal(): number {
    let total = 0;
    if (this.expenses) {
      this.expenses.forEach((expense) => {
        total += Number(expense.amount);
      });
    }
    return total;
  }
}

export default CentrisListing;
