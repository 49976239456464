/* eslint-disable prefer-destructuring */
import _ from 'lodash';

import { getViewerURL, PLACE_OPTION_TYPES, VIEWER_BASE_URL_REVIEW } from 'config/constants';
import { currentDateTime } from 'utils';
import { ILocationDetails } from 'types';
import Broker from './Broker';
import Shooting from './Shooting';
import Room from './Room';
import Picture from './Picture';
import Video from './Video';
import DetailedFloorplan from './DetailedFloorPlan';
import AutomatedVideo from './AutomatedVideo';

const VIDEO360_EN = '360 video';
const VIDEO360_FR = 'Vidéo 360';
const MATTERPORT = 'Matterport';

class Listing {
  id: number;

  address: string;

  app: string;

  broker: Broker;

  broker_id: number;

  city: string;

  civic_number: string;

  client_id: number;

  commercial: boolean;

  created_at: string;

  date_notarized: string;

  date_off_market: string;

  date_sold: string;

  distribution_token: string;

  validated_shooting_id: number;

  expired: boolean;

  is_house: boolean;

  last_mark: string;

  lat: string;

  lng: string;

  markers_count: number;

  mls: string;

  notarized: boolean;

  on_market: boolean;

  picture_checksum: string;

  picture_url: string;

  postpone_reason: string;

  price: number;

  priority: number;

  property_type: number = Listing.TYPE_ENUM.HOUSE;

  sd_card_id: number;

  show_mesures: boolean;

  sold: boolean;

  street: string;

  surface_area: string;

  tech_id: string;

  temp_id: string;

  updated_at: string;

  postalcode: string;

  video_360_url: string;

  youtube_video_360_id: string;

  facebook_video_360_url: string;

  state: number;

  shootings: Shooting[] = [];

  detailed_floorplan_service: DetailedFloorplan[] = [];

  video_service_offer_media: Video[] = [];

  iframe_service_offer_media: Video[] = [];

  pictures: Picture[] = [];

  picture_feedback: string;

  rooms: Room[] = [];

  province: string;

  country: string;

  general_notes: [];

  url_params: string;

  centris_listing: any;

  media_notification_logs?: any[] = [];

  automated_videos: AutomatedVideo[] = [];

  floorplanServices: any[] = [];

  uneditedPictures?: Picture[] = [];

  ai_staging_orders?: any[] = [];

  virtualServices?: any[] = [];

  nextAiStagingOrderIsFree?: boolean;

  centrisUploadService?: any;

  static STATE = {
    CREATED: 0,
    VALIDATED: 2,
    VR_READY: 3,
  };

  static TYPE_ENUM = {
    HOUSE: 1,
    CONDO: 2,
    PLEX: 3,
    COMMERCIAL: 4,
  };

  static TYPES = [
    { type: 1, name: 'house' },
    { type: 2, name: 'condo' },
    { type: 3, name: 'plex' },
    { type: 4, name: 'commercial' },
  ];

  static PROPERTY_SIZE_IDENTIFIER = {
    SQUARE_FOOTAGE: 'SQUARE_FOOTAGE',
    ROOM_COUNT: 'ROOM_COUNT',
  };

  constructor(data: object = {}) {
    Object.assign(this, data);
    if (this.broker) {
      this.broker = new Broker(this.broker);
    }

    if (this.shootings) {
      this.shootings = this.shootings.map((s: object) => {
        return new Shooting(s);
      });
    }

    if (this.rooms) {
      this.rooms = this.rooms.map((r: object) => {
        return new Room(r);
      });
    }

    if (this.pictures) {
      this.pictures = _.orderBy(
        this.pictures,
        ['shooting_id', 'picture_index'],
        ['asc', 'asc'],
      ).map((p: object) => {
        return new Picture(p);
      });
    }
    if (this.uneditedPictures) {
      this.uneditedPictures = this.uneditedPictures.map((p: object) => {
        return new Picture(p);
      });
    }
    if (this.media_notification_logs) {
      this.media_notification_logs = this.media_notification_logs.map((l: any) => {
        return { id: l.id, email: l.email, phone_number: l.phone_number, timestamp: l.timestamp };
      });
    }

    if (this.video_service_offer_media) {
      this.video_service_offer_media = this.video_service_offer_media.map((v: object) => {
        return new Video(v);
      });
    }

    if (this.detailed_floorplan_service) {
      this.detailed_floorplan_service = this.detailed_floorplan_service.map((v: object) => {
        return new DetailedFloorplan(v);
      });
    }

    if (this.iframe_service_offer_media) {
      this.iframe_service_offer_media = this.iframe_service_offer_media.map((v: object) => {
        return new Video(v);
      });
    }
  }

  get cityAddress(): string {
    const parts: string[] = [];

    parts.push(this.address);
    parts.push(this.app ? `#${this.app}` : null);
    parts.push(this.city);

    return _.compact(parts).join(', ');
  }

  get cityWithPostalCode(): string {
    const parts: string[] = [];

    parts.push(this.city);
    parts.push(this.postalcode);

    return _.compact(parts).join(', ');
  }

  get cityAddressWithPostalCode(): string {
    const parts: string[] = [];

    parts.push(this.cityAddress);
    parts.push(this.postalcode);

    return _.compact(parts).join(', ');
  }

  get fullAddress(): string {
    const parts: string[] = [];

    parts.push(this.cityAddressWithPostalCode);
    parts.push(this.country);

    return _.compact(parts).join(', ');
  }

  get listingAddress() {
    const parts: string[] = [];

    parts.push(this.address);
    parts.push(this.city);

    if (this.app) {
      parts.push(`App:${this.app}`);
    }

    return _.compact(parts).join(', ');
  }

  get isVRReady() {
    return this.state === Listing.STATE.VR_READY;
  }

  get availablePictures(): Picture[] {
    if (!this.pictures) return [];

    return this.pictures.filter((pic: Picture) => {
      return pic.thumbnail_url != null;
    });
  }

  get updatedPictures(): Picture[] {
    return this.pictures;
  }

  get picturesWithComments(): Picture[] {
    return this.pictures.filter((pic: Picture) => {
      return pic.tickets.length > 0;
    });
  }

  picturesWithUpdatedComments(pictureIndex, ticket_id): Picture[] {
    const currentTickets = this.pictures[pictureIndex].tickets;
    const updatedTickets = currentTickets.filter((ticket) => {
      return ticket.id !== ticket_id;
    });
    this.pictures[pictureIndex].tickets = updatedTickets;
    return this.pictures;
  }

  get insideRooms(): Room[] {
    if (!this.rooms) return [];

    return this.rooms.filter((room: Room) => {
      return room.isInsideRoom;
    });
  }

  get videos(): Video[] {
    const videos: Video[] = [];

    if (this.video_service_offer_media) {
      videos.push(...this.video_service_offer_media);
    }

    if (this.video_360_url) {
      videos.push(
        new Video({
          name: VIDEO360_EN,
          display_name: VIDEO360_EN,
          display_name_fr: VIDEO360_FR,
          url: `${this.video_360_url}?${currentDateTime()}`,
          youtube_video_id: this.youtube_video_360_id,
        }),
      );
    }

    return videos;
  }

  get virtualVisitURL() {
    return `${getViewerURL()}/visit/${this.url_params}`;
  }

  get reviewVirtualVisitURL() {
    return `https://oldviewer.hausvalet.ca/visit/${this.id}`;
  }

  get reviewEsoftVirtualVisitURL() {
    return `${VIEWER_BASE_URL_REVIEW}/visit/${this.url_params}`;
  }

  get matterPortURL() {
    let matterPort: Video = null;

    if (this.iframe_service_offer_media) {
      matterPort = this.iframe_service_offer_media.find((v: Video) => {
        return v.name === MATTERPORT;
      });
    }

    return matterPort ? matterPort.url : null;
  }

  get sizeIdentifier() {
    if (this.property_type === Listing.TYPE_ENUM.COMMERCIAL) {
      return Listing.PROPERTY_SIZE_IDENTIFIER.SQUARE_FOOTAGE;
    }
    return Listing.PROPERTY_SIZE_IDENTIFIER.ROOM_COUNT;
  }

  get service_offers() {
    const serviceOffers = [];
    const added_service_offers = new Set();

    const isServiceOfferAlreadyAdded = (serviceOffer: any) => {
      return added_service_offers.has(serviceOffer.category);
    };

    this.shootings.forEach((shooting) => {
      shooting.service_offers.forEach((service_offer) => {
        if (!isServiceOfferAlreadyAdded(service_offer.serviceOffer)) {
          serviceOffers.push(service_offer.serviceOffer);
          added_service_offers.add(service_offer.serviceOffer.category);
        }
      });
    });

    if (serviceOffers.length <= 3) {
      if (this.url_params !== null) {
        serviceOffers.push({ id: 999 }, { id: 998 });
      } else {
        serviceOffers.push({ id: 999 });
      }
    } else if (serviceOffers.length === 4) {
      serviceOffers.push({ id: 999 });
    }

    return serviceOffers;
  }

  get delivery_page_url() {
    if (this.distribution_token && this.distribution_token !== '') {
      return `https://media.hausvalet.ca/delivery/${this.distribution_token}`;
    }
    return null;
  }

  static parseGooglePlaceDetails(
    description: string,
    data: { [key: string]: any },
  ): ILocationDetails {
    let cityLevel = 10;
    let civicNumber = '';
    let street = '';
    let city = '';
    let province = '';
    let country = '';
    let postalCode = '';
    let address = '';

    const { lat, lng } = data.geometry?.location || {};
    if (!lat || !lng) {
      return null;
    }

    let foundCivicNumber = false;

    if (data.address_components) {
      data.address_components.forEach((element: any) => {
        element.types.forEach((type: string) => {
          switch (type) {
            case 'street_number':
              civicNumber = element.long_name;
              if (street !== '') {
                address = `${civicNumber} ${street}`;
              }
              foundCivicNumber = true;
              break;
            case 'route':
              street = element.long_name;
              if (civicNumber !== '') {
                address = `${civicNumber} ${street}`;
              } else {
                address = street;
              }
              break;
            case 'locality':
              if (city === '' || cityLevel > 0) {
                city = element.long_name;
                cityLevel = 0;
              }
              break;
            case 'sublocality':
              if (city === '' || cityLevel > 1) {
                city = element.long_name;
                cityLevel = 1;
              }
              break;
            case 'administrative_area_level_3':
              if (city === '' || cityLevel > 3) {
                city = element.long_name;
                cityLevel = 3;
              }
              break;
            case 'administrative_area_level_4':
              if (city === '' || cityLevel > 4) {
                city = element.long_name;
                cityLevel = 4;
              }
              break;
            case 'administrative_area_level_5':
              if (city === '' || cityLevel > 5) {
                city = element.long_name;
                cityLevel = 5;
              }
              break;
            case 'administrative_area_level_1':
              province = element.long_name;
              break;
            case 'country':
              country = element.long_name;
              break;
            case 'postal_code':
              postalCode = element.long_name;
              break;
            case 'postal_code_prefix':
              if (postalCode === '') {
                postalCode = element.long_name;
              }
              break;
            default:
              break;
          }
        });
      });
    }

    if (!foundCivicNumber && description) {
      const parts = description.split(' ');
      if (parts.length > 1) {
        civicNumber = parts[0];
        address = `${civicNumber} ${street}`;
      }
    }

    return {
      address,
      civic_number: civicNumber,
      street,
      city,
      province,
      country,
      postalcode: postalCode,
      lat: lat(),
      lng: lng(),
    };
  }

  static parseCanadaPlaceDetails(data: { [key: string]: any }): ILocationDetails {
    const street = data.Street;
    const civicNumber = data.BuildingNumber;

    return {
      address: `${civicNumber} ${street}`,
      civic_number: civicNumber,
      app: data.SubBuilding,
      street,
      city: data.City,
      province: data.ProvinceName,
      country: 'Canada',
      postalcode: data.PostalCode,
      lat: data.lat,
      lng: data.lng,
    };
  }

  static parseAddressFromDetails(
    description: string,
    data: { [key: string]: any },
    type: string,
  ): ILocationDetails {
    if (!data) return null;

    if (type === PLACE_OPTION_TYPES.GOOGLE) {
      return Listing.parseGooglePlaceDetails(description, data);
    }
    if (type === PLACE_OPTION_TYPES.POST_CANADA) {
      return Listing.parseCanadaPlaceDetails(data);
    }

    return null;
  }
}

export default Listing;
